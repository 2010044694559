<template>
  <section>
    <div class="navbar-background">
      <div>
        <b-navbar style="background-color: rgba(0, 0, 0, 0)" transparent>
          <template #brand>
            <a @click="$router.push({name: 'Cars'})">
            <div>
              <b-image :src="require('@/assets/img/home/logo.png')"></b-image>
            </div>
            </a>
          </template>

          <template #end>
            <b-navbar-dropdown right>
              <template #label>
                <div class="has-text-white">
                  {{ clientName }}
                </div>
              </template>
              <b-navbar-item tag="router-link" :to="{ name: 'Profile' }">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="account"
                    type="is-primary"
                  ></b-icon>
                  <div class="media-content">Moje dane</div>
                </div>
              </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ name: 'Cars' }">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="car"
                    type="is-primary"
                  ></b-icon>
                  <div class="media-content">Moje pojazdy</div>
                </div>
              </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ name: 'Orders' }">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="file-document"
                    type="is-primary"
                  ></b-icon>
                  <div class="media-content">Moje zamówienia</div>
                </div>
              </b-navbar-item>
              <b-navbar-item tag="router-link" to="/explore">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="magnify"
                    type="is-primary"
                  ></b-icon>
                  <div class="media-content">Wyszukiwarka usług</div>
                </div>
              </b-navbar-item>
              <hr class="dropdown-divider" />
              <b-navbar-item @click="logout">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="logout"
                    type="is-danger"
                  ></b-icon>
                  <div class="media-content">Wyloguj</div>
                </div>
              </b-navbar-item>
            </b-navbar-dropdown>
          </template>
        </b-navbar>
      </div>
      <div style="height: 10vh" class="mt-4">
<!--         <nav class="is-flex is-justify-content-space-evenly has-text-white">
          <div>AKTUALNOŚCI</div>
          <div>WYDARZENIA</div>
          <div>SKLEP</div>
          <div>FORUM</div>
          <div>SERWIS OGŁOSZENIOWY</div>
          <div>STREFA KLIENTA</div>
          <div>KONTAKT</div>
        </nav>
        <hr /> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      localStorage.clear();
      this.$router.push({ name: "Home" });
    },

    goToExplore() {
      this.$router.push({ name: "ExploratorStartSearch" });
    },
  },

  computed: {
    clientName() {
      return this.$store.getters["clientProfile/name"];
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-background {
  background-image: url("../assets/img/banners/banner7.png");
  background-size: cover;
}
</style>